@use '@angular/material' as mat;
@import '@alfresco/adf-core/theming';

@import 'custom-theme';
@import 'variables/variables';

@include custom-theme($custom-theme);

$focus-color: #1f74db;
$contrast-gray: #646569;

@mixin rounded-border($border-color, $thickness: 2px, $radius: 4px) {
  border: $thickness solid $border-color;
  border-radius: $radius;
}

.mat-toolbar {
  color: var(--theme-text-color, rgba(0, 0, 0, 0.54));
}

.adf-name-location-cell-location {
  color: $contrast-gray !important;
}

.mat-form-field-label, .mat-tab-label, .mat-checkbox-label, .mat-toolbar {
  color: $contrast-gray !important;
  opacity: 1 !important;
}

.mat-tab-label-active {
  color: #212121 !important;
}

.adf-upload-dialog {
  &__header, &__content {
    color: $contrast-gray;
  }
}

.adf-version-list-item {
  &-comment, &-date {
    color: $contrast-gray;
    opacity: 1 !important;
  }
}

.mat-chip.mat-standard-chip {
  background-color: #efefef;
  color: $contrast-gray;
}

.adf-textitem-edit-icon.mat-icon {
  color: $contrast-gray !important;
}

.adf-property-field.adf-card-textitem-field:hover .adf-property-clear-value {
  color: $contrast-gray !important;
}

.mat-icon-button, .mat-button, .mat-checkbox {
  &:focus {
    @include rounded-border($focus-color);
  }
  .mat-ripple-element {
    display: none;
  }
  &.cdk-focused {
    .mat-button-focus-overlay {
      display: none;
    }
    .mat-checkbox-ripple {
      @include rounded-border($focus-color);
      height: 22px;
      width: 22px;
      left: calc(50% - 13px);
      top: calc(50% - 13px);
    }
  }
}

.adf-content-node-selector-content-list .adf-datatable-list .adf-datatable-selected > svg {
  fill: #1f74db !important;
}

.mat-list-base .mat-list-option:focus {
  background-color: white;
  .mat-list-item-content {
    border: 2px solid #1f74db;
  }
}

.mat-menu-item {
  &:focus {
    @include rounded-border($focus-color);
    background-color: white;
  }
  &.cdk-keyboard-focused:not([disabled]) {
    background-color: white;
  }
  &.cdk-focused {
    .mat-menu-ripple {
      display: none;
    }
  }
}

.mat-option {
  &.cdk-focused {
    .mat-option-ripple {
      @include rounded-border($focus-color);
    }
  }
}

.mat-slide-toggle {
  .mat-slide-toggle-thumb {
      width: 15px;
      height: 15px;
      transform: translate(20%, 50%);
      background-color: #484a4d;
  }

  .mat-slide-toggle-bar {
    border-radius: 15px;
    height: 20px;
  }

  .mat-slide-toggle-thumb-container {
    top: -5px;
  }

  &.mat-primary.mat-checked:not(.mat-disabled) {
    .mat-slide-toggle-bar {
      background-color: $focus-color;
    }
    .mat-slide-toggle-thumb {
      background-color: white;
    }
  }

  .mat-slide-toggle-ripple {
    display: none;
  }
  &.cdk-focused {
    .mat-slide-toggle-bar {
      border: 2px solid $focus-color;
    }
  }
}

.mat-stroked-button, .mat-flat-button {
  &.cdk-focused {
    .mat-button-ripple.mat-ripple {
      border: 2px solid $focus-color;
      top: -4px;
      left: -4px;
      bottom: -4px;
      right: -4px;
    }
  }
}

.mat-option {
  &.cdk-focused {
    .mat-option-ripple {
      @include rounded-border($focus-color);
    }
  }
}

.mat-datetimepicker-calendar-body-active .mat-datetimepicker-calendar-body-cell-content:not(.mat-datetimepicker-calendar-body-selected) {
  background-color: white !important;
  border: 2px solid $focus-color;
}

.mat-datetimepicker-calendar-body-cell-content.mat-datetimepicker-calendar-body-selected {
  background-color: #2254b2;
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]) {
  background-color: white;
  border: 2px solid $focus-color;
}

.adf-error-snackbar {
  background-color: #ba1b1b;

  .mat-simple-snackbar, .mat-simple-snackbar-action {
    color: white;
  }
}

.adf-info-snackbar {
  background-color: $focus-color;

  .mat-simple-snackbar, .mat-simple-snackbar-action {
      color: white;
  }
}

.adf-empty-content__icon {
  color: $contrast-gray;
}
