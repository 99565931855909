@use '@angular/material' as mat;
$warn: map-get($custom-theme, warn);
$accent: map-get($custom-theme, accent);
$primary: map-get($custom-theme, primary);

$foreground: map-get($custom-theme, foreground);
$background: map-get($custom-theme, background);

//Custom variables - ACA specific styling:
$document-list-selection-color: mat.get-color-from-palette($alfresco-ecm-blue, 500);
$document-list-background: white;
$data-table-dividers-wrapper-border: none;
$data-table-thumbnail-width: 35px;
$data-table-cell-min-width: 150px;
$data-table-cell-min-width--no-grow: 120px;
$data-table-cell-min-width--fileSize: 110px !important;
$data-table-cell-text-color: mat.get-color-from-palette($foreground, text, 0.54);
$data-table-cell-link-color: mat.get-color-from-palette($foreground, text);
$data-table-hover-color: #e3fafd;
$data-table-selection-color: #e3fafd;

$adf-pagination--border: 1px solid mat.get-color-from-palette($foreground, text, 0.07);
$adf-pagination__empty--height: 0;

$adf-toolbar-single-row-height: 48px;
$adf-toolbar-padding: 14px;

$adf-upload-dragging-color: unset;
$adf-upload-dragging-border: 1px solid #00bcd4;
$adf-upload-dragging-background: #e0f7fa;
$adf-upload-dragging-level1-color: unset;
$adf-upload-dragging-level1-border: none;

$adf-permission-list-width: 100%;

$grey-background: rgba(33, 33, 33, 0.12);
$grey-text-background: rgba(33, 33, 33, 0.05);
$grey-hover-background: rgba(33, 33, 33, 0.24);
$blue-save-button-background: #1F74DB;
$black-heading: #4e4c4c;
$grey-dropdown-background: #eee;
$grey-divider: rgba(0,0,0,.22);

$defaults: (
  --theme-primary-color: mat.get-color-from-palette($primary),
  --theme-primary-color-default-contrast: mat.get-color-from-palette($primary, default-contrast),
  --theme-warn-color: mat.get-color-from-palette($warn),
  --theme-accent-color: mat.get-color-from-palette($accent),
  --theme-background-color: mat.get-color-from-palette($background, background),
  --theme-text-color: mat.get-color-from-palette($foreground, text, 0.54),
  --theme-text-bold-color: mat.get-color-from-palette($foreground, text, 0.87),
  --theme-title-color: mat.get-color-from-palette($foreground, text, 0.87),
  --theme-text-disabled-color: mat.get-color-from-palette($foreground, text, 0.38),
  --theme-border-color: mat.get-color-from-palette($foreground, text, 0.07),
  --header-background-image: url('/assets/images/mastHead-bg-shapesPattern.svg'),
  --theme-card-background-color: mat.get-color-from-palette($background, card),
  --theme-foreground-text-color: mat.get-color-from-palette($foreground, text, 0.72),
  --theme-foreground-text-bold-color: mat.get-color-from-palette($foreground, text, 0.87),
  --theme-secondary-text-color: mat.get-color-from-palette($foreground, secondary-text),
  --theme-divider-color: mat.get-color-from-palette($foreground, divider, 0.07),
  --theme-dialog-background-color: mat.get-color-from-palette($background, dialog),

  --new-button-font-size: 0.9rem,
  --theme-grey-text-background-color: $grey-text-background,
  --theme-grey-background-color: $grey-background,
  --theme-grey-hover-background-color: $grey-hover-background,
  --theme-blue-button-color: $blue-save-button-background,
  --theme-heading-color: $black-heading,
  --theme-dropdown-color: $grey-dropdown-background,
  --theme-grey-divider-color: $grey-divider,
);

// propagates SCSS variables into the CSS variables scope
:root {
  @each $name, $value in $defaults {
    #{$name}: #{$value};
  }
}
