/* vietnamese */
@font-face {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 400;
    src: local('Muli Regular'),
         local('Muli-Regular'),
         url(Muli-Regular.ttf) format('truetype');
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 400;
    src: local('Muli Regular'),
         local('Muli-Regular'),
         url(Muli-Regular.ttf) format('truetype');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 400;
    src: local('Muli Regular'),
         local('Muli-Regular'),
         url(Muli-Regular.ttf) format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  }

/* vietnamese */
@font-face {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 100;
    src: local('Muli Light'),
    local('Muli-Light'),
    url(Muli-Light.ttf) format('truetype');
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 100;
    src: local('Muli Light'),
    local('Muli-Light'),
    url(Muli-Light.ttf) format('truetype');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 100;
    src: local('Muli Light'),
    local('Muli-Light'),
    url(Muli-Light.ttf) format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
