/* You can add global styles to this file, and also import other style files */
@import 'app/ui/application';
@import './app/ui/variables/font-family';

body,
html {
  height: 100%;
  font-family: $default-font-family;
}

body {
  margin: 0;
}
